<template>
  <div class="swiper-seven">
    <div class="title">智慧园区数据生态服务商</div>
    <div class="article">
      提供智能数据全生命周期全域产品与解决方案，双引擎加速助力政企数字化转型
    </div>
  </div>
</template>
<script>
export default {
  name: 'swiper-seven',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.swiper-seven {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 60px;
  margin-bottom: 40px;
  font-weight: 600;
  letter-spacing: 4px;
}

.article {
  font-size: 18px;
  margin-bottom: 5px;
}
</style>
