var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/image/home_logo.png"),"alt":"","srcset":""}}),_c('div',{staticClass:"menu"},_vm._l((_vm.menuList),function(o,i){return _c('div',{key:i,class:[_vm.menuIndex == i ? 'select-menu-item' : ''],on:{"mouseenter":function($event){return _vm.mouseEnter(i)},"mouseleave":function($event){return _vm.mouseLeave(i)}}},[_vm._v(" "+_vm._s(o)+" ")])}),0),_c('div',{staticClass:"approval"},[_vm._v("联系我们")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.proShow),expression:"proShow"}],class:['programme', _vm.proClass ? 'show' : 'hide'],on:{"mouseenter":_vm.mouseEnterMenu,"mouseleave":_vm.mouseLeaveMenu}},[_c('div',{staticClass:"middle"},[_c('div',[_c('div',{class:[
            _vm.listIndex == 1 ? 'act-title' : '',
            'list-item',
            'margin-right'
          ],on:{"click":function($event){return _vm.selectListItem(1)}}},[_c('div',[_c('div',{staticClass:"order-box title_1"}),_c('div',{class:_vm.listIndex == 1 ? 'act-text' : ''},[_vm._v("智慧园区")])]),_vm._m(0)]),_c('div',{class:[_vm.listIndex == 2 ? 'act-title' : '', 'list-item'],on:{"click":function($event){return _vm.selectListItem(2)}}},[_c('div',[_c('div',{staticClass:"order-box title_2"}),_c('div',{class:_vm.listIndex == 2 ? 'act-text' : ''},[_vm._v("数据资产入表")])]),_vm._m(1)])]),_c('div',[_c('div',{class:[
            _vm.listIndex == 3 ? 'act-title' : '',
            'list-item',
            'margin-right'
          ],on:{"click":function($event){return _vm.selectListItem(3)}}},[_c('div',[_c('div',{staticClass:"order-box title_3"}),_c('div',{class:_vm.listIndex == 3 ? 'act-text' : ''},[_vm._v("智慧社区")])]),_vm._m(2)]),_c('div',{class:[_vm.listIndex == 4 ? 'act-title' : '', 'list-item'],on:{"click":function($event){return _vm.selectListItem(4)}}},[_c('div',[_c('div',{staticClass:"order-box title_4"}),_c('div',{class:_vm.listIndex == 4 ? 'act-text' : ''},[_vm._v("数据治理")])]),_vm._m(3)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"order-box"}),_c('div',[_vm._v(" 为产业园区提供\"异构数据融合+园区数据中台+园区数据应用\"数据聚合与应用解决方案。 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"order-box"}),_c('div',[_vm._v("提供各类数据应用服务，实现资产价值最大化。")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"order-box"}),_c('div',[_vm._v(" 通过智慧物业管理平台实现了系统集成，为小区居民提供安全、高效、便捷的智能服务。 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"order-box"}),_c('div',[_vm._v(" 从采、存、管、用四大方面构建数据治理体系，实现数字化经营。 ")])])}]

export { render, staticRenderFns }