<template>
  <div class="swiper-two">
    <div class="middle">
      <div class="title">数据资产入表服务链合体</div>
      <div class="article">
        为数据要素市场建设提供整体规划咨询、数据资产化全流程的技术、产品和解决方案服务，进行数据资产盘点、估值、入表核算
      </div>
      <div class="tag-box">
        <div class="tag-title">工具链</div>
        <div class="tag-list">
          <div>数据治理</div>
          <div>数据资产盘点</div>
          <div>数据分类分级</div>
          <div>系统建设</div>
          <div>数据安全</div>
          <div>初次数据入表服务</div>
        </div>
        <div class="tag-title margin-top40">服务链</div>
        <div class="tag-list">
          <div>数据合规服务</div>
          <div>数据产品孵化</div>
          <div>数据估值服务</div>
          <div>再入表服务</div>
          <div>战略规划</div>
          <div>渠道赋能</div>
        </div>
        <div class="tag-title margin-top40">供应链</div>
        <div class="tag-list margin-bottom20">
          <div>资产管理</div>
          <div>金融服务</div>
          <div class="empty"></div>
          <div class="empty"></div>
          <div class="empty"></div>
          <div class="empty"></div>
        </div>
      </div>
      <div class="arrow-box">
        <img src="@/assets/image/arrow.png" alt="" srcset="" @click="next" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'swiper-two',
  data() {
    return {}
  },
  methods: {
    next() {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-two {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middle {
  width: 80%;
}

.title {
  font-size: 44px;
  font-weight: 600;
}

.article {
  margin-top: 15px;
}

.tag-box {
  margin-top: 30px;
  width: 100%;
  background-color: rgb(0 0 0 / 0.3);
  padding: 16px 30px;
  box-sizing: border-box;
}

.tag-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.tag-list {
  display: flex;
}

.tag-list > div {
  flex: 1;
  border: 1px solid #033c8d;
  background-color: #033c8d;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
  padding: 8px 0;
  border-radius: 4px;
}

.tag-list > div:last-child {
  margin-right: 0;
}

.margin-top40 {
  margin-top: 40px;
}

.empty {
  border: 1px solid rgb(0 0 0 / 0) !important;
  background-color: rgb(0 0 0 / 0) !important;
  cursor: auto !important;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.arrow-box {
  text-align: center;
  margin-top: 50px;
}

.arrow-box img {
  cursor: pointer;
}

@media (max-width: 800px) {
  /* .title {
    font-size: 44px;
  } */

  .swiper-two {
    font-size: 12px;
  }
}

@media (min-width: 801px) {
  /* .title {
    font-size: 44px;
  } */

  .swiper-two {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  /* .title {
    font-size: 44px;
  } */

  .swiper-two {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  /* .title {
    font-size: 44px;
  } */

  .swiper-two {
    font-size: 15px;
  }
}

@media (min-width: 1400px) {
  /* .title {
    font-size: 44px;
  } */

  .swiper-two {
    font-size: 17px;
  }
}

@media (min-width: 1600px) {
  /* .title {
    font-size: 44px;
  } */

  .swiper-two {
    font-size: 19px;
  }
}
</style>
