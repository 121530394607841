<template>
  <div class="swiper-four">
    <div class="middle">
      <div class="title">核心产品</div>
      <div class="list">
        <div
          @mouseover="mouseMove(1)"
          @mouseleave="mouseMove()"
          :class="[index == 1 ? 'active_bg' : '']"
        >
          <div class="left">
            <div class="step-bg">
              <img src="@/assets/image/first.png" alt="" srcset="" />
            </div>
          </div>
          <div class="right">
            <div class="right-title">元数据管理平台</div>
            <div class="right-content">
              厘清数据资源，掌握数据来龙去脉，精确反应数据血缘，为数据资产入表工作提供可靠的评估和审计依据。
            </div>
            <div class="view">
              <div>
                <span>立即查看</span><span><i class="el-icon-right"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div
          @mouseover="mouseMove(2)"
          @mouseleave="mouseMove()"
          :class="[index == 2 ? 'active_bg' : '']"
        >
          <div class="left">
            <div class="step-bg">
              <img src="@/assets/image/second.png" alt="" srcset="" />
            </div>
          </div>
          <div class="right">
            <div class="right-title">数据资产管理平台</div>
            <div class="right-content">
              为数据资产入表工作提供有效载体，从数据分级分类与数据资源目录生成，到数据产品包装，再到数据资产管理运营、数据资产管理平台提供全场景应用支撑。
            </div>
            <div class="view">
              <div>
                <span>立即查看</span><span><i class="el-icon-right"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div
          @mouseover="mouseMove(3)"
          @mouseleave="mouseMove()"
          :class="[index == 3 ? 'active_bg' : '']"
        >
          <div class="left">
            <div class="step-bg">
              <img src="@/assets/image/third.png" alt="" srcset="" />
            </div>
          </div>
          <div class="right">
            <div class="right-title">数据质量管理平台</div>
            <div class="right-content">
              通过构建数据全生命周期的数据质检体系，智能化监控数据资产运营情况，有效提升数据资产质量，确保数据资产的有效性，可靠性与高价值。
            </div>
            <div class="view">
              <div>
                <span>立即查看</span><span><i class="el-icon-right"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div
          @mouseover="mouseMove(4)"
          @mouseleave="mouseMove()"
          :class="[index == 4 ? 'active_bg' : '']"
        >
          <div class="left">
            <div class="step-bg">
              <img src="@/assets/image/fourth.png" alt="" srcset="" />
            </div>
          </div>
          <div class="right">
            <div class="right-title">数据标准管理平台</div>
            <div class="right-content">
              提供数据资产标准规范应用，为数据质量监控提供可靠依据，为数据资产入表与数据资产运营提供落标指导，实现了全局数据的统一规范与统一计量。
            </div>
            <div class="view">
              <div>
                <span>立即查看</span><span><i class="el-icon-right"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow-box">
        <img src="@/assets/image/arrow.png" alt="" srcset="" @click="next" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'swiper-four',
  data() {
    return {
      index: 0
    }
  },
  methods: {
    mouseMove(i) {
      if (!i) return (this.index = 0)
      if (this.index == i) return
      this.index = i
    },
    next() {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-four {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middle {
  width: 65%;
}

.title {
  text-align: center;
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 50px;
}

.list {
  display: flex;
  margin-bottom: 60px;
}

.list > div {
  flex: 1;
  aspect-ratio: 520/209;
  background-image: url('../../../../assets/image/step_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 90px;
  display: flex;
}

.active_bg {
  background-image: url('../../../../assets/image/step_act_bg.png') !important;
}

.list > div:last-child {
  margin-right: 0;
}

.left {
  width: 20%;
  padding: 22px;
  box-sizing: border-box;
}

.step-bg {
  width: 100%;
  padding-top: 113.9%;
  position: relative;
}

.step-bg > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.right {
  flex: 1;
  padding: 0.9em;
  display: flex;
  flex-direction: column;
}

.right-title {
  color: #3583f1;
  font-size: 1.2em;
  margin-top: 0.4em;
}

.right-content {
  flex: 1;
  margin-top: 0.9em;
  font-size: 0.9em;
}

.view {
  color: #3583f1;
  font-size: 0.9em;
}

.view > div {
  cursor: pointer;
}

.view > div > span:first-child {
  margin-right: 1em;
}

.el-icon-right {
  font-size: 1.2em;
}

.arrow-box {
  text-align: center;
  margin-top: 50px;
}

.arrow-box > img {
  cursor: pointer;
}

@media (max-width: 800px) {
  .swiper-four {
    font-size: 12px;
  }
}

@media (min-width: 801px) {
  .swiper-four {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .swiper-four {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .swiper-four {
    font-size: 15px;
  }
}

@media (min-width: 1400px) {
  .swiper-four {
    font-size: 17px;
  }
}

@media (min-width: 1600px) {
  .swiper-four {
    font-size: 19px;
  }
}
</style>
