<template>
  <div class="swiper-one">
    <div class="title">数据资产入表解决方案</div>
    <div class="article">
      高效数据资产入表规划，专业数据合规，精确成本分摊，释放数据价值，
    </div>
    <div class="article">助力国央企和上市公司一站式数据资产如表。</div>
    <div class="botton-box">
      <div>
        <el-button type="primary">开始免费试用</el-button>
        <el-button type="primary" plain>立即咨询</el-button>
      </div>
    </div>
    <div class="arrow-box" @click="next">
      <img src="@/assets/image/arrow.png" alt="" srcset="" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'swiper-one',
  data() {
    return {}
  },
  methods: {
    next() {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-one {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin-bottom: 40px;
  font-weight: 600;
  letter-spacing: 4px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.article {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.botton-box {
  height: 20%;
  display: flex;
  align-items: flex-end;
}

.arrow-box {
  cursor: pointer;
  height: 28%;
  display: flex;
  align-items: center;
}

.arrow-box img {
  cursor: pointer;
}
</style>
