<template>
  <div class="swiper-three">
    <div class="middle">
      <div class="title">
        和<span class="blue-text">15万+</span>企业用户共同成长
      </div>
      <div class="partner">
        <div class="partner-row">
          <div
            class="tian_an"
            @mouseover="enterMask(1)"
            @mouseleave="outerMask(1)"
          >
            <div class="mask" v-show="index != 1"></div>
          </div>
          <div
            class="feng_huo"
            @mouseover="enterMask(2)"
            @mouseleave="outerMask(2)"
          >
            <div class="mask" v-show="index != 2"></div>
          </div>
          <div
            class="jiao_yi_suo"
            @mouseover="enterMask(3)"
            @mouseleave="outerMask(3)"
          >
            <div class="mask" v-show="index != 3"></div>
          </div>
          <div
            class="hua_yu"
            @mouseover="enterMask(4)"
            @mouseleave="outerMask(4)"
          >
            <div class="mask" v-show="index != 4"></div>
          </div>
          <div
            class="bei_ming"
            @mouseover="enterMask(5)"
            @mouseleave="outerMask(5)"
          >
            <div class="mask" v-show="index != 5"></div>
          </div>
        </div>
      </div>
      <div class="arrow-box">
        <img src="@/assets/image/arrow.png" alt="" srcset="" @click="next" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'swiper-three',
  data() {
    return {
      index: 1,
      timer: null
    }
  },
  mounted() {
    this.carouselMask()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    carouselMask() {
      this.timer = setInterval(() => {
        const i = this.index
        if (0 < i && i < 5) {
          this.index += 1
        } else {
          this.index = 1
        }
      }, 5000)
    },
    enterMask(i) {
      if (this.index == i) return
      clearInterval(this.timer)
      this.index = i
    },
    outerMask(i) {
      this.index = i
      this.carouselMask()
    },
    next() {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-three {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middle {
  width: 60%;
}

.title {
  text-align: center;
  font-size: 44px;
  font-weight: 600;
}

.blue-text {
  color: #1b64da;
}

.arrow-box {
  text-align: center;
  margin-top: 50px;
}

.arrow-box img {
  cursor: pointer;
}

.partner {
  width: 100%;
  height: 30vh;
  margin: 60px 0 40px 0;
}

.partner-row {
  display: flex;
}

.partner-row > div {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  aspect-ratio: 2/1;
}

.partner-row > div:last-child {
  margin-right: 0;
}

.tian_an {
  background-image: url('../../../../assets/image/tian_an.png');
}

.feng_huo {
  background-image: url('../../../../assets/image/feng_huo.png');
}

.jiao_yi_suo {
  background-image: url('../../../../assets/image/jiao_yi_suo.png');
}

.hua_yu {
  background-image: url('../../../../assets/image/hua_yu.png');
}

.bei_ming {
  background-image: url('../../../../assets/image/bei_ming.png');
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.3);
}
</style>
