<template>
  <div class="swiper-five">
    <div class="middle">
      <div class="title">应用场景</div>
      <div class="five-bg">
        <div class="list-img">
          <div class="list-img-bg top five-parts">
            <div>数据资产化</div>
            <div>数据战略与治理</div>
            <div>数据资产与运营</div>
            <div>数据要素建议</div>
            <div>数据资产盘点</div>
          </div>
        </div>
        <div class="list-img margin-top20">
          <div class="list-img-bg bottom">
            <div class="one-parts">数据价值与<br />分析发现</div>
            <div class="two-parts">数据安全与合规</div>
            <div class="two-parts">建立数据资产质量标准体系</div>
          </div>
        </div>
      </div>
      <div class="arrow-box">
        <img src="@/assets/image/arrow.png" alt="" srcset="" @click="next" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'swiper-five',
  data() {
    return {}
  },
  mounted() {},
  destroyed() {},
  methods: {
    next() {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-five {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middle {
  width: 62.5%;
}

.title {
  text-align: center;
  font-size: 44px;
  font-weight: 600;
}

.arrow-box {
  text-align: center;
  margin-top: 2.5em;
}

.arrow-box > img {
  cursor: pointer;
}

.five-bg {
  margin-top: 1.5em;
}

.list-img {
  width: 100%;
  position: relative;
  padding-top: 25%;
}

.list-img-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
}

.top {
  background-image: url('../../../../assets/image/five_top.png');
}

.bottom {
  background-image: url('../../../../assets/image/five_bottom.png');
}

.five-parts > div {
  flex: 1;
  text-align: center;
  padding-top: 3em;
}

.five-parts > div:first-child {
  padding-top: 4em;
  font-size: 1.1em;
}

.one-parts {
  flex: 1;
  text-align: center;
  padding-top: 3em;
  font-size: 1.2em;
}

.two-parts {
  flex: 2;
  padding-top: 3em;
  text-align: center;
}

.margin-top20 {
  margin-top: 2em;
}

@media (max-width: 800px) {
  .swiper-five {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (min-width: 801px) {
  .swiper-five {
    font-size: 14px;
    font-weight: 600;
  }
}

@media (min-width: 1024px) {
  .swiper-five {
    font-size: 15px;
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .swiper-five {
    font-size: 18px;
    font-weight: 600;
  }
}

@media (min-width: 1400px) {
  .swiper-five {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
