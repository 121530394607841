<template>
  <!-- element-loading-spinner="el-icon-loading" v-loading="!finishAnimation" element-loading-background="rgba(0, 0, 0, 0.1)" -->
  <div class="swiper-item" v-on:wheel="mouseWheel">
    <div ref="swiper_1" class="swiper-bg swiper-url1">
      <swiper-one @next="mouseWheel({ deltaY: 100 })"></swiper-one>
    </div>
    <div ref="swiper_2" class="swiper-bg swiper-url2">
      <swiper-two @next="mouseWheel({ deltaY: 100 })"></swiper-two>
    </div>
    <div ref="swiper_3" class="swiper-bg swiper-url3">
      <swiper-three @next="mouseWheel({ deltaY: 100 })"></swiper-three>
    </div>
    <div ref="swiper_4" class="swiper-bg swiper-url4">
      <swiper-four @next="mouseWheel({ deltaY: 100 })"></swiper-four>
    </div>
    <div ref="swiper_5" class="swiper-bg swiper-url5">
      <swiper-five @next="mouseWheel({ deltaY: 100 })"></swiper-five>
    </div>
    <!-- <div ref="swiper_6" class="swiper-bg swiper-url6">
      <swiper-six @next="mouseWheel({ deltaY: 120 })"></swiper-six>
    </div> -->
    <!-- 原6删掉，原7变6 -->
    <div ref="swiper_6" class="swiper-bg swiper-url6">
      <swiper-seven @next="mouseWheel({ deltaY: 100 })"></swiper-seven>
    </div>
    <div class="swiper-index">
      <div
        v-for="i in 6"
        :key="i"
        :class="[index == i ? 'active-circle' : 'out-circle']"
        @click="toSomePage(i)"
      >
        <div class="circle" v-if="index != i"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Velocity from 'velocity-animate'
import swiperOne from './components/swiper-one.vue'
import swiperTwo from './components/swiper-two.vue'
import swiperThree from './components/swiper-three.vue'
import swiperFour from './components/swiper-four.vue'
import swiperFive from './components/swiper-five.vue'
// import swiperSix from './components/swiper-six.vue'
import swiperSeven from './components/swiper-seven.vue'
export default {
  name: 'swiper',
  data() {
    return {
      index: 1,
      finishAnimation: true
    }
  },
  components: {
    swiperOne,
    swiperTwo,
    swiperThree,
    swiperFour,
    swiperFive,
    // swiperSix,
    swiperSeven
  },
  created() {},
  methods: {
    mouseWheel(e) {
      console.log('%ce', 'color: red', e)
      if (!this.finishAnimation) return
      if (e.deltaY > 0) {
        if (this.index == 6) {
          this.toSomePage(1, 1)
        } else {
          const curTra = this.trajectory(
            `swiper_${this.index}`,
            `swiper_${this.index + 1}`,
            e.deltaY
          )
          this.scaleItem(curTra, e.deltaY)
        }
      } else {
        if (this.index == 1) {
          this.toSomePage(6, -1)
        } else {
          const curTra = this.trajectory(
            `swiper_${this.index}`,
            `swiper_${this.index - 1}`,
            e.deltaY
          )
          this.scaleItem(curTra, e.deltaY)
        }
      }
    },
    scaleItem(trajectory, sign, num) {
      console.log('%cnum', 'color: red', num)
      this.finishAnimation = false
      Velocity(this.$refs[trajectory.el], trajectory.operate, {
        duration: trajectory.time,
        complete: () => {
          if (!trajectory.trajectory) {
            this.finishAnimation = true
            console.log('%cnum', 'color: red', num)
            if (num) {
              // 1,2,3,4,5,6 时为点击
              this.index = num
              console.log('%cthis.index', 'color: red', this.index)
            } else {
              // sign ±100 时候为滚动
              if (Math.sign(sign) == 1) {
                // 向下滚动
                this.index += 1
              } else if (Math.sign(sign) == -1) {
                // 向上滚动
                this.index -= 1
              }
            }
          } else {
            this.scaleItem(trajectory.trajectory, sign, num)
          }
        }
      })
    },
    trajectory(curEl, nexEl, sign) {
      let curTra = {
        // 显示
        el: nexEl,
        operate: { opacity: [1, 'easeInOutQuart', 0] },
        time: 500,
        trajectory: {
          // 缩小
          el: curEl,
          operate: { scale: [0.8, 1], zIndex: 1 },
          time: 500,
          trajectory: {
            // 移动
            el: curEl,
            operate: { translateX: sign > 0 ? -2000 : 2000 },
            time: 100,
            trajectory: {
              // 隐藏
              el: curEl,
              operate: { opacity: [0, 'easeInOutQuart', 1] },
              time: 100,
              trajectory: {
                // 大小恢复
                el: curEl,
                operate: { scale: [1, 0.8] },
                time: 10,
                trajectory: {
                  // 位置恢复
                  el: curEl,
                  operate: { translateX: 0 },
                  time: 10,
                  trajectory: {
                    // 层级向下
                    el: curEl,
                    operate: { zIndex: -1 },
                    time: 10,
                    trajectory: {
                      el: nexEl,
                      operate: { zIndex: 0 },
                      time: 10
                    }
                  }
                }
              }
            }
          }
        }
      }
      return curTra
    },
    toSomePage(i, dir) {
      if (this.index == i) return
      this.finishAnimation = false
      let direction
      if (dir) {
        direction = dir
      } else {
        direction = i / this.index > 1 ? 1 : -1
      }
      const curTra = this.trajectory(
        `swiper_${this.index}`,
        `swiper_${i}`,
        direction
      )
      console.log('i', i)
      this.scaleItem(curTra, '', i)
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-item {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 44px;
}

.swiper-url1 {
  background-image: url('../../../assets/image/1.png');
  opacity: 1;
  z-index: 0;
}

.swiper-url2 {
  background-image: url('../../../assets/image/2.png');
  opacity: 0;
  z-index: -1;
}

.swiper-url3 {
  background-image: url('../../../assets/image/3.png');
  opacity: 0;
  z-index: -1;
}

.swiper-url4 {
  background-image: url('../../../assets/image/4.png');
  opacity: 0;
  z-index: -1;
}

.swiper-url5 {
  background-image: url('../../../assets/image/5.png');
  opacity: 0;
  z-index: -1;
}

/* .swiper-url6 {
  background-image: url('../../../assets/image/6.png');
  opacity: 0;
  z-index: -1;
} */

.swiper-url6 {
  background-image: url('../../../assets/image/7.png');
  opacity: 0;
  z-index: -1;
}

.swiper-index {
  position: fixed;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.active-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: none;
  border: 2px solid red;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.out-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: none;
  border: 2px solid rgb(0 0 0 / 0);
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-item ::v-deep .el-icon-loading {
  font-size: 60px;
}
</style>
