<template>
  <div class="relative">
    <div class="header">
      <img src="@/assets/image/home_logo.png" alt="" srcset="" />
      <div class="menu">
        <!-- @click="selectMenu(i)" -->
        <div
          :class="[menuIndex == i ? 'select-menu-item' : '']"
          v-for="(o, i) in menuList"
          :key="i"
          @mouseenter="mouseEnter(i)"
          @mouseleave="mouseLeave(i)"
        >
          {{ o }}
        </div>
      </div>
      <div class="approval">联系我们</div>
    </div>
    <div
      :class="['programme', proClass ? 'show' : 'hide']"
      v-show="proShow"
      @mouseenter="mouseEnterMenu"
      @mouseleave="mouseLeaveMenu"
    >
      <!-- <div class="close" @click="proHide">
        <i class="el-icon-close"></i>
      </div> -->
      <div class="middle">
        <div>
          <div
            :class="[
              listIndex == 1 ? 'act-title' : '',
              'list-item',
              'margin-right'
            ]"
            @click="selectListItem(1)"
          >
            <div>
              <div class="order-box title_1"></div>
              <div :class="listIndex == 1 ? 'act-text' : ''">智慧园区</div>
            </div>
            <div>
              <div class="order-box"></div>
              <div>
                为产业园区提供"异构数据融合+园区数据中台+园区数据应用"数据聚合与应用解决方案。
              </div>
            </div>
          </div>
          <div
            :class="[listIndex == 2 ? 'act-title' : '', 'list-item']"
            @click="selectListItem(2)"
          >
            <div>
              <div class="order-box title_2"></div>
              <div :class="listIndex == 2 ? 'act-text' : ''">数据资产入表</div>
            </div>
            <div>
              <div class="order-box"></div>
              <div>提供各类数据应用服务，实现资产价值最大化。</div>
            </div>
          </div>
        </div>
        <div>
          <div
            :class="[
              listIndex == 3 ? 'act-title' : '',
              'list-item',
              'margin-right'
            ]"
            @click="selectListItem(3)"
          >
            <div>
              <div class="order-box title_3"></div>
              <div :class="listIndex == 3 ? 'act-text' : ''">智慧社区</div>
            </div>
            <div>
              <div class="order-box"></div>
              <div>
                通过智慧物业管理平台实现了系统集成，为小区居民提供安全、高效、便捷的智能服务。
              </div>
            </div>
          </div>
          <div
            :class="[listIndex == 4 ? 'act-title' : '', 'list-item']"
            @click="selectListItem(4)"
          >
            <div>
              <div class="order-box title_4"></div>
              <div :class="listIndex == 4 ? 'act-text' : ''">数据治理</div>
            </div>
            <div>
              <div class="order-box"></div>
              <div>
                从采、存、管、用四大方面构建数据治理体系，实现数字化经营。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'self-title',
  data() {
    return {
      menuList: [
        '首页',
        '产品介绍',
        '解决方案',
        // '数据治理',
        // '产品解决方案',
        // '行业解决方案案例',
        // '数字化转型',
        // '赋能中心',
        // '伙伴',
        '关于'
      ],
      menuIndex: 0,
      proShow: false,
      proClass: false,
      listIndex: 1,
      enterParents: false,
      enterSon: false
    }
  },
  methods: {
    // selectMenu(i) {
    //   if (i == 2) {
    //     this.proShow = i == 2
    //     this.proClass = i == 2
    //   } else {
    //     this.proHide()
    //   }
    //   if (this.menuIndex == i) return
    //   this.menuIndex = i
    // },
    proHide() {
      this.proClass = false
      setTimeout(() => {
        this.proShow = false
      }, 500)
    },
    selectListItem(i) {
      if (this.listIndex == i) return
      this.listIndex = i
    },
    mouseEnter(i) {
      this.menuIndex = i
      if (i == 2) {
        this.enterParents = true
        this.proShow = i == 2
        this.proClass = i == 2
      }
    },
    mouseLeave(i) {
      if (i == 2) {
        this.enterParents = false
        setTimeout(() => {
          if (!this.enterSon) {
            this.proHide()
          }
        })
      }
    },
    mouseEnterMenu() {
      this.enterSon = true
    },
    mouseLeaveMenu() {
      this.enterSon = false
      setTimeout(() => {
        if (!this.enterParents) {
          this.proHide()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
}

.header {
  height: 70px;
  background-color: #020e24;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}

.menu {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  font-size: 1em;
}

.menu > div {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  box-sizing: border-box;
}

.menu > div:last-child {
  margin: 0;
}

.select-menu-item {
  margin-top: 2px !important;
  border-bottom: 2px solid #ed8001;
}

.approval {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: #002e7c;
  cursor: pointer;
}

.programme {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 340px;
  z-index: 9;
  background-color: #020e24;
}

.middle {
  margin: 0 300px 0 300px;
  height: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.middle > div {
  flex: 1;
  display: flex;
}

.middle > div:last-child {
  margin-top: 30px;
}

.list-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #051a43;
  cursor: pointer;
}

.act-title {
  background-image: url('../../../assets/image/act_title.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.act-text {
  color: #1954ba;
}

.margin-right {
  margin-right: 10%;
}

.list-item > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.15rem;
}

.list-item > div:last-child {
  margin-top: 10px;
  font-size: 0.88rem;
}

.order-box {
  width: 1.6rem;
  height: 1.6rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.title_1 {
  background-image: url('../../../assets/image/title_1.png');
}

.title_2 {
  background-image: url('../../../assets/image/title_2.png');
}

.title_3 {
  background-image: url('../../../assets/image/title_3.png');
}

.title_4 {
  background-image: url('../../../assets/image/title_4.png');
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 1.4rem;
}

.show {
  animation: show 0.5s;
}

@keyframes show {
  0% {
    transform: translate(0, -70px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

.hide {
  animation: hide 0.5s;
}

@keyframes hide {
  100% {
    transform: translate(0, -280px);
  }
  0% {
    transform: translate(0, 0px);
  }
}
</style>
