<template>
  <div class="home">
    <self-title></self-title>
    <div class="swiper">
      <swiper></swiper>
    </div>
  </div>
</template>

<script>
import SelfTitle from '@/components/home/title/title.vue'
import swiper from '@/components/home/swiper/swiper.vue'
export default {
  name: 'Home',
  data() {
    return {}
  },
  activated() {},
  components: {
    SelfTitle,
    swiper
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  height: 70px;
  background-color: #020e24;
  color: #fff;
}

.swiper {
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
